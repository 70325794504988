import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";
import marked from "marked";
import Translator from "../i18n/Translator";

const ReviewCard = ({ review }) => {
  const [cardHeight, setCardHeight] = useState();
  const [fullHeight, setFullHeight] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const ref = useRef();

  useEffect(() => {
    ref.current && setCardHeight(showAll ? ref.current.scrollHeight : 200);
  }, [ref, showAll]);

  const text = marked(review.fields.text);
  return (
    <div className='review-card'>
      <div
        ref={ref}
        className='review-card__contents'
        style={{ height: cardHeight }}
      >
        <div className='review-card__text'>
          {review.fields.title && (
            <h3
              dangerouslySetInnerHTML={{ __html: marked(review.fields.title) }}
            />
          )}
          {review.fields.source && (
            <h5
              dangerouslySetInnerHTML={{ __html: marked(review.fields.source) }}
            />
          )}
          {review.fields.source2 && (
            <h5
              dangerouslySetInnerHTML={{
                __html: marked(review.fields.source2),
              }}
            />
          )}

          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        {review.fields.image && (
          <div className='review-card__image'>
            <img src={review.fields.image.fields.file.url} />
          </div>
        )}
        {!showAll && <div className='review-card--fade' />}
      </div>
      <button onClick={() => setShowAll(!showAll)} className='button'>
        {!showAll ? (
          <Translator path='ReadMore' />
        ) : (
          <Translator path='ReadLess' />
        )}
      </button>
    </div>
  );
};

export default ReviewCard;
