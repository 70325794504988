import React, { useEffect, useState } from "react";
import { getBiog } from "../contentful";

import marked from "marked";

const Biog = () => {
  const [content, setContent] = useState(null);
  const [text, setText] = useState("");

  useEffect(() => {
    getBiog().then((data) => setContent(data[0].fields));
  }, []);

  let paragraph;
  if (content) {
    if (!localStorage.lang || localStorage.lang === "nl-NL") {
      paragraph = marked(content.textNl);
    } else if (localStorage.lang === "en-US") {
      paragraph = marked(content.textEn);
    } else if (localStorage.lang === "fr-FR") {
      paragraph = marked(content.textFr);
    } else if (localStorage.lang === "de-DE") {
      paragraph = marked(content.textDe);
    }
  }

  return (
    <div className='page-content'>
      <div className='biog-page'>
        {content && (
          <div className='biog-page__image'>
            <img src={content.image.fields.file.url} />
          </div>
        )}
        {content && (
          <section
            className='biog-page__text'
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        )}
      </div>
    </div>
  );
};

export default Biog;
