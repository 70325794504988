import React from "react";

const RecordingsHero = ({
  homepage,
  title,
  solo,
  organ,
  harpsichord,
  recordings,
}) => {
  let cds;

  if (solo) cds = recordings.filter((r) => r.fields.solo);
  if (organ) cds = recordings.filter((r) => r.fields.organ);
  if (harpsichord) cds = recordings.filter((r) => r.fields.harpsichord);
  if (homepage) cds = recordings.filter((r) => r.fields.homepage);

  return (
    <>
      <h4 style={{ textAlign: "center" }}>{title}</h4>
      <div className='recordings-panel'>
        {cds.map((r) => (
          <div key={r.fields.title} className='cd-card'>
            <div className='cd-card__cover'>
              <img src={r.fields.cover.fields.file.url} />
            </div>
            {r.fields.title && (
              <div className='cd-card__title'>
                <span>{r.fields.title}</span>
              </div>
            )}
            {r.fields.details && (
              <div className='cd-card__details'>
                <div className='line' />
                <span>{r.fields.details}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default RecordingsHero;
