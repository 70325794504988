import React, { useState, useEffect } from "react";
import { getProjects, getProjectsPage } from "../contentful";
import ProjectCard from "../components/ProjectCard";
import marked from "marked";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [background, setBackground] = useState(null);

  useEffect(() => {
    getProjects().then((data) => setProjects(data));
    getProjectsPage().then((data) =>
      setBackground(data[0].fields.backgroundImage.fields.file.url)
    );
  }, []);
  return (
    <div
      className='page-background'
      style={
        background
          ? {
              backgroundImage: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(${background})`,
            }
          : {}
      }
    >
      <div className='page-content '>
        <h1>Projects</h1>

        {projects.map((p) => {
          let title;
          let desc;

          if (projects) {
            if (!localStorage.lang || localStorage.lang === "nl-NL") {
              desc = p.fields.descriptionNl;
              title = p.fields.titleNl;
            } else if (localStorage.lang === "en-US") {
              desc = p.fields.descriptionEn;
              title = p.fields.titleEn;
            } else if (localStorage.lang === "fr-FR") {
              desc = p.fields.descriptionFr;
              title = p.fields.titleFr;
            } else if (localStorage.lang === "de-DE") {
              desc = p.fields.descriptionDe;
              title = p.fields.titleDe;
            }
          }

          return (
            <ProjectCard
              title={title}
              desc={desc}
              details={p.fields.fullProgramme}
              image={p.fields.image.fields.file.url}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
