import React, { useState } from "react";
import { FaLinkedin, FaInstagram, FaFacebook } from "react-icons/fa";
import I18n from "../i18n/i18n";
import { NavLink } from "react-router-dom";
import Translator from "../i18n/Translator";
import { NAV_ITEMS } from "../../config";

import "./Toolbar-styles.scss";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";

const Toolbar = (props) => {
  const [mediaDropdown, setMediaDropdown] = useState(false);

  return (
    <header className='toolbar'>
      <nav className='toolbar__navigation'>
        <div className='toolbar__toggle-button'>
          <DrawerToggleButton click={props.drawerClickHandler} />
        </div>
        <div className='toolbar__logo'>
          <NavLink to='/' className='toolbar-link '>
            <span className='brand'>
              Ben Van Nespen - <Translator path='OrganistKlavecinist' />{" "}
            </span>
          </NavLink>
        </div>
        <div className='spacer' />
        <div className='toolbar_navigation-items'>
          <div className='nav-right'>
            {NAV_ITEMS.map((item) => (
              <NavLink
                key={item.path}
                activeClassName='active'
                className='toolbar-link'
                to={item.path}
              >
                <Translator path={item.label} />
              </NavLink>
            ))}
            <div className='dropdown-container'>
              <span
                onClick={() => setMediaDropdown(!mediaDropdown)}
                className='toolbar-link'
              >
                <Translator path='Media' />
              </span>

              <div className={`dropdown-list ${mediaDropdown && "list-open"} `}>
                <NavLink onClick={() => setMediaDropdown(false)} to='/gallery'>
                  <Translator path='Gallery' />
                </NavLink>
                <NavLink onClick={() => setMediaDropdown(false)} to='/media'>
                  <Translator path='Media' />
                </NavLink>
              </div>
            </div>
            <span onClick={props.openContact} className='toolbar-link'>
              <Translator path='Contact' />
            </span>

            <I18n />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Toolbar;
