import React, { useState, useEffect } from "react";
import { getYoutube } from "../contentful";
import marked from "marked";

const Media = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    getYoutube().then((data) => setVideos(data));
  }, []);
  return (
    <div className='page-content'>
      <div className='videos-container'>
        {videos.map((v) => (
          <div className='video-card'>
            <section
              dangerouslySetInnerHTML={{ __html: marked(v.fields.youtubeCode) }}
            />
            {v.fields.description && (
              <span
                dangerouslySetInnerHTML={{
                  __html: marked(v.fields.description),
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Media;
